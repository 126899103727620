import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router-dom/Link';
import canUseDOM from './../../../utils/canUseDom';

class RankedCompany extends Component {
  constructor() {
    super();

    this.state = {
      creditActive: false
    };

    this.handleCreditClick = this.handleCreditClick.bind(this);
  }

  handleCreditClick() {
    this.setState({
      creditActive: !this.state.creditActive
    });
  }

  renderCompanies(companies) {
    const data = JSON.parse(companies || '[]');
    const total = data.length - 1;
    const markup = data.map((company, index) => (
      <Link
        key={company.url}
        to={`/innovation-by-design/2018/company/${company.url}`}
      >
        {company.name}
        {index < total ? ', ' : ''}
      </Link>
    ));
    return <h3 className="ranked-company__name">{markup}</h3>;
  }

  render() {
    const {
      body,
      description,
      image,
      name,
      product_creators: productCreators,
      titleBefore
    } = this.props;
    const { creditActive } = this.state;

    return (
      <div className="ranked-company">
        <div
          className={`ranked-company__deck ${
            titleBefore ? '' : 'ranked-company__deck--spacer'
          }`}
          dangerouslySetInnerHTML={{ __html: name }}
        />
        {this.renderCompanies(productCreators)}
        {image && (
          <img
            className="ranked-company__image"
            src={`https://a.fastcompany.net/image/fetch/w_599,ar_16:9,c_fill,q_auto,f_auto,fl_lossy/https:${image}`}
            alt=""
          />
        )}
        {canUseDOM() &&
          description && (
            <p
              className="ranked-company__description"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
        {body &&
          !creditActive && (
            <span
              className="ranked-company__credit-link"
              onClick={this.handleCreditClick}
            >
              See Credits
            </span>
          )}
        {body && (
          <div
            className={`ranked-company__credit ${creditActive &&
              'ranked-company__credit--active'}`}
            dangerouslySetInnerHTML={{ __html: body }}
          />
        )}
      </div>
    );
  }
}

RankedCompany.defaultProps = {
  body: '',
  description: '',
  name: '',
  image: '',
  product_creators: '',
  titleBefore: false
};

RankedCompany.propTypes = {
  body: PropTypes.string,
  description: PropTypes.string,
  name: PropTypes.string,
  image: PropTypes.string,
  product_creators: PropTypes.string,
  titleBefore: PropTypes.bool
};

export default RankedCompany;
