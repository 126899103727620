import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Social from '../social/social';

class RankedIBDMenu extends Component {
  constructor() {
    super();

    this.state = {
      active: null
    };

    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
  }

  toggleMobileMenu(value) {
    const { active } = this.state;
    const nextState =
      value === active || (typeof value !== 'number' && !value) ? null : value;

    this.setState({
      active: nextState
    });
  }

  render() {
    const { menu, social } = this.props;
    const { active } = this.state;

    return (
      <div className="ranked-mobile-menu">
        <div className="ranked-mobile-menu__btn-container">
          {menu.map((item, index) => {
            if (item.title === 'All Winners') {
              return (
                <a
                  className="ranked-mobile-menu__btn"
                  key={`ranked-mobile-item-${index}`}
                  href="https://www.fastcompany.com/innovation-by-design/2018"
                >
                  {item.title}
                </a>
              );
            }
            return (
              <div
                className="ranked-mobile-menu__btn"
                onClick={() => this.toggleMobileMenu(index)}
                key={`ranked-mobile-item-${index}`}
              >
                {item.title}
              </div>
            );
          })}
          {social ? (
            <div
              className="ranked-mobile-menu__btn ranked-mobile-menu__btn--share"
              onClick={() => this.toggleMobileMenu('social')}
            >
              <i className="icon-video-share" />
            </div>
          ) : null}
        </div>
        {typeof active === 'number' ? (
          <div className="ranked-mobile-menu__panel ranked-mobile-menu__panel--ibd">
            <div className="ranked-mobile-menu__title ranked-mobile-menu__title--ibd">
              <img
                src="https://images.fastcompany.net/image/upload/v1534893759/fcweb/ibd-2018-small_kg7ok2.jpg"
                alt=""
              />
            </div>
            <div
              className="ranked-mobile-menu__close"
              onClick={() => this.toggleMobileMenu()}
            />
            <ul className="ranked-mobile-menu__list">
              {menu[active].list.map((item, index) => (
                <li key={`ranked-mobile-menu-item-${index}`}>
                  <a
                    href={`https://www.fastcompany.com/innovation-by-design/2018/category/${
                      item.url
                    }`}
                  >
                    {item.category_name || item.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ) : null}

        {active && active === 'social' ? (
          <div className="ranked-mobile-menu__social">
            <Social
              empty={false}
              enableSticky={false}
              modifier="mic-2018"
              uri="https://www.fastcompany.com/innovation-by-design/2018/"
              subject="Innovation by Design Awards 2018"
              text={encodeURIComponent(
                `It’s official: the 2018 @FastCompany Innovation by Design winners might be the most impressive, inspiring group yet. See the full list of #FCDesignAwards honorees here:`
              )}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

RankedIBDMenu.propTypes = {
  menu: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  social: PropTypes.shape({}).isRequired
};

export default RankedIBDMenu;
