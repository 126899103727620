import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Social from '../social/social';

class RankedMobileMenu extends Component {
  constructor() {
    super();

    this.state = {
      active: null
    };

    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
    this.goToPerson = this.goToPerson.bind(this);
  }

  toggleMobileMenu(value) {
    const { active } = this.state;
    const nextState =
      value === active || (typeof value !== 'number' && !value) ? null : value;

    this.setState({
      active: nextState
    });
  }

  goToPerson(rank) {
    const { scrollTo } = this.props;

    this.toggleMobileMenu();
    scrollTo(rank);
  }

  renderPersonName(type, item) {
    const { pad } = this.props;

    // console.log(item);

    if (type === 'name') {
      return (
        <div className="ranked-mobile-menu__entry-container">
          <div className="ranked-mobile-menu__right-column">
            <div className="ranked-mobile-menu__name">
              {item.first_name} {item.last_name}
            </div>
            <div className="ranked-mobile-menu__job-title">
              {item.job_title}
            </div>
            <div className="ranked-mobile-menu__company">
              {item.company_name}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="ranked-mobile-menu__entry-container">
        <div className="ranked-mobile-menu__left-column">
          {pad(item.ranking)}
        </div>

        <div className="ranked-mobile-menu__right-column">
          <div className="ranked-mobile-menu__name">
            {item.first_name} {item.last_name}
          </div>
          <div className="ranked-mobile-menu__job-title">{item.job_title}</div>
          <div className="ranked-mobile-menu__company">{item.company_name}</div>
        </div>
      </div>
    );
  }

  render() {
    const { menu, social } = this.props;
    const { active } = this.state;

    return (
      <div className="ranked-mobile-menu">
        <div className="ranked-mobile-menu__btn-container">
          {menu.map((item, index) => (
            <div
              className="ranked-mobile-menu__btn"
              onClick={() => this.toggleMobileMenu(index)}
              key={`ranked-mobile-item-${index}`}
            >
              {item.title}
            </div>
          ))}
          {social ? (
            <div
              className="ranked-mobile-menu__btn ranked-mobile-menu__btn--share"
              onClick={() => this.toggleMobileMenu('social')}
            >
              <img
                src="https://images.fastcompany.net/image/upload/v1556317705/fcweb/Share-icon_pjj7bv.svg"
                alt="SHARE"
              />
            </div>
          ) : null}
        </div>

        {typeof active === 'number' ? (
          <div className="ranked-mobile-menu__panel">
            <div className="ranked-mobile-menu__title" />
            <div
              className="ranked-mobile-menu__close"
              onClick={() => this.toggleMobileMenu()}
            />
            <ul className="ranked-mobile-menu__list">
              {menu[active].list.map((item, index) => (
                <li
                  key={`ranked-mobile-menu-item-${index}`}
                  onClick={() => this.goToPerson(item.url)}
                >
                  {this.renderPersonName(menu[active].type, item)}
                </li>
              ))}
            </ul>
          </div>
        ) : null}

        {active && active === 'social' ? (
          <div className="ranked-mobile-menu__social">
            {/* TODO: rename modifier and socail props */}
            <Social
              empty={false}
              enableSticky={false}
              modifier="mic-2018"
              uri="https://www.fastcompany.com/most-innovative-companies/2018/"
              text={encodeURIComponent(
                `@FastCompany's Most Innovative Companies 2018 #FCMostInnovative`
              )}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

RankedMobileMenu.propTypes = {
  menu: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pad: PropTypes.func.isRequired,
  scrollTo: PropTypes.func.isRequired,
  social: PropTypes.shape({}).isRequired
};

export default RankedMobileMenu;
