import React from 'react';
import PropTypes from 'prop-types';

const RankedPagination = props => {
  const { scrollTo, prev, next, pad, mic, mcp } = props;
  const isActive = prev.ranking && next.ranking;
  let renderButton;
  if (!mic && isActive) {
    renderButton = (person, modifier) => {
      const {
        ranking,
        first_name: firstName,
        last_name: lastName,
        url
      } = person;

      if (mcp) {
        return (
          <button
            className={`ranked-pagination__btn ranked-pagination__btn--${modifier} ranked-pagination__btn--mcp`}
            onClick={() => scrollTo(url)}
          >
            <span className="ranked-pagination__name">{pad(ranking)}</span>
          </button>
        );
      } else if (firstName && lastName) {
        return (
          <button
            className={`ranked-pagination__btn ranked-pagination__btn--${modifier}`}
            onClick={() => scrollTo(url)}
          >
            <span className="ranked-pagination__name">
              {pad(ranking)} {firstName} {lastName}
            </span>
          </button>
        );
      }

      return null;
    };
  } else {
    renderButton = (company, modifier) => {
      const { ranking, name } = company;

      if (name) {
        return (
          <button
            className={`ranked-pagination__btn ranked-pagination__btn--${modifier} ranked-pagination__btn--mic`}
            onClick={() => scrollTo(ranking)}
          >
            <span className="ranked-pagination__name ranked-pagination__name--mic">
              {pad(ranking)} {name}
            </span>
          </button>
        );
      }

      return null;
    };
  }

  return (
    <div
      className={`ranked-pagination ${mic && 'ranked-pagination--mic'} ${mcp &&
        'ranked-pagination--mcp'} ${
        isActive ? 'ranked-pagination--active' : ''
      }`}
    >
      {renderButton(prev, 'prev')}
      {renderButton(next, 'next')}
    </div>
  );
};
RankedPagination.defaultProps = {
  mic: false,
  mcp: false
};

RankedPagination.propTypes = {
  scrollTo: PropTypes.func.isRequired,
  next: PropTypes.shape({}).isRequired,
  prev: PropTypes.shape({}).isRequired,
  pad: PropTypes.func.isRequired,
  mic: PropTypes.bool,
  mcp: PropTypes.bool
};

export default RankedPagination;
