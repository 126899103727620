import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router-dom/Link';
import get from 'lodash/get';
import striptags from 'striptags';
import FCLink from '../fc_link/fc_link';
import { popupCenter, trackSocial } from '../../../utils/share_util';

function processAssetServerImage(imgSrc) {
  if (typeof imgSrc !== 'string') {
    return '';
  }

  return imgSrc.replace('a.fastcompany', 'images.fastcompany');
}

class RankedPerson extends Component {
  constructor() {
    super();

    this.state = {
      mobileActive: false
    };

    this.expand = this.expand.bind(this);
  }

  setClasses(ranking) {
    const { group } = this.props;
    const classes = ['ranked-person', `rank-${ranking}`];

    group.map(person => classes.push(person.url));

    return classes.join(' ');
  }

  strip(str) {
    return striptags(str, ['a', 'b', 'em', 'i', 'u', 'strike', 'strong']);
  }

  expand() {
    this.setState({
      mobileActive: true
    });
  }

  shareTwitter(event, url) {
    event.preventDefault();
    popupCenter(event, url, 560, 725);
    trackSocial('twitter', 'tweet');
  }

  rankingSlug() {
    const { group, pad } = this.props;
    const first = get(group, '[0].ranking');
    const last = get(group, `[${group.length - 1}].ranking`);
    let ranking = '';

    if (first) {
      ranking += pad(first);
    }

    if (last && last !== first) {
      ranking += `-${pad(last)}`;
    }

    return ranking;
  }

  generateTwitterShare(people, hashedUrl, year) {
    const reasonWhy = people[0].why;

    const names = people.map(
      person => `${person.first_name} ${person.last_name}`
    );
    let lastPerson = ',';
    let twitterCopy = `is one of @FastCompany’s Most Creative People of ${year}: ${reasonWhy} #FCMostCreative `;

    if (names.length > 1) {
      lastPerson = ` and ${names.pop()},`;
      twitterCopy = `are some of @FastCompany’s Most Creative People of ${year}: ${reasonWhy} #FCMostCreative`;
    }

    const twitterUrl = `https://twitter.com/share?url=${hashedUrl}&text=Why ${`${names.join(
      ', '
    )}${lastPerson} ${encodeURIComponent(twitterCopy)}`}`;

    return twitterUrl;
  }

  render() {
    const { config, group, index, year } = this.props;
    const { mobileActive } = this.state;
    const ranking = this.rankingSlug(group);
    const firstPerson = get(group, '[0]', {});
    const hashedUrl = encodeURIComponent(
      `https://www.fastcompany.com/most-creative-people/${year}#rank-${ranking}`
    );
    const twitter = this.generateTwitterShare(group, hashedUrl, year);

    return (
      <div className={this.setClasses(ranking)} data-index={index}>
        <header className="ranked-person__header">
          <div className="ranked-person__title-container">
            {firstPerson.related_article ? (
              <h3 className="ranked-person__why">
                <a href={firstPerson.related_article}>
                  <span>{ranking}</span> {firstPerson.why}
                </a>
              </h3>
            ) : (
              <h3 className="ranked-person__why">
                <span>{ranking}</span> {firstPerson.why}
              </h3>
            )}
            <div className="ranked-person__names">
              {group.map(person => (
                <Link
                  key={person.url}
                  className="ranked-person__link"
                  to={`/person/${person.url}`}
                >
                  <h4 className="ranked-person__name">
                    <strong>
                      {person.first_name} {person.last_name}
                    </strong>
                    {person.job_title ? `, ${person.job_title}` : ''}
                    {person.company_name ? `, ${person.company_name}` : ''}
                  </h4>
                </Link>
              ))}
            </div>
            <a
              href={twitter}
              className="ranked-person__twitter social__link"
              onClick={event => this.shareTwitter(event, twitter)}
            >
              <i className="icon-twitter" />
            </a>
          </div>
          {firstPerson.media ? (
            <figure className="ranked-person__art">
              <img src={firstPerson.media} alt="" />
              <figcaption
                style={{ fontSize: '13px' }}
                dangerouslySetInnerHTML={{
                  __html: this.strip(
                    `${firstPerson.caption} [${firstPerson.credits}]`
                  )
                }}
              />
            </figure>
          ) : null}
        </header>
        <div className={`ranked-person__profiles ${this.props.mcp && 'mcp'}`}>
          {group.map(person => (
            <figure
              className={`ranked-person__profile ${this.props.mcp && 'mcp'}`}
              key={person.url}
            >
              <a href={`/person/${person.url}`}>
                <img src={processAssetServerImage(person.thumb_url)} alt="" />
              </a>

              <Link
                className="ranked-person__profile-link"
                to={`/person/${person.url}`}
              >
                {(this.props.mcp && group.length > 1) ||
                (this.props.mcp &&
                  (this.props.year === 2018 || this.props.year === 2019))
                  ? `${person.first_name} ${person.last_name}`
                  : 'Read Bio'}
              </Link>
            </figure>
          ))}
        </div>
        <div
          className={`ranked-person__content ${
            mobileActive ? 'ranked-person__content--active' : ''
          } ${this.props.mcp && 'mcp'}`}
        >
          <div
            className={`ranked-person__profiles-mobile ${this.props.mcp &&
              'mcp'}`}
          >
            {group.map(person => (
              <figure
                className={`ranked-person__profile ${this.props.mcp && 'mcp'}`}
                key={person.url}
              >
                <Link to={`/person/${person.url}`}>
                  <img src={processAssetServerImage(person.thumb_url)} alt="" />
                </Link>

                <Link
                  className="ranked-person__profile-link"
                  to={`/person/${person.url}`}
                >
                  {this.props.mcp && group.length > 1
                    ? `${person.first_name} ${person.last_name}`
                    : 'Read Bio'}
                </Link>
              </figure>
            ))}
          </div>
          <div dangerouslySetInnerHTML={{ __html: firstPerson.summary }} />
          {firstPerson.related_article ? (
            <FCLink
              className="ranked-person__article"
              to={firstPerson.related_article}
              config={config}
            >
              Read More
            </FCLink>
          ) : null}
        </div>
      </div>
    );
  }
}

RankedPerson.defaultProps = {
  mcp: false,
  year: 2019
};

RankedPerson.propTypes = {
  config: PropTypes.shape({}).isRequired,
  group: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pad: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  mcp: PropTypes.bool,
  year: PropTypes.number
};

export default RankedPerson;
